import React, { useState, useEffect } from "react";
import { Button, Spin, Input, Table, Tooltip } from "antd";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { BASE } from "../apis/constant";

const StoredResume = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(BASE.getResumeData);
        setData(response.data.resumes);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const filteredData = data.filter((resume) => {
    return (
      resume.filename.toLowerCase().includes(searchText) ||
      resume.name.toLowerCase().includes(searchText) ||
      resume.contact.toLowerCase().includes(searchText) ||
      resume.address.toLowerCase().includes(searchText) ||
      resume.education.toLowerCase().includes(searchText) ||
      resume.skills.toLowerCase().includes(searchText) ||
      resume.experience.toLowerCase().includes(searchText) ||
      resume.domain.toLowerCase().includes(searchText)
    );
  });

  const columns = [
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
      ellipsis: true,
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Contact", dataIndex: "contact", key: "contact" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Education", dataIndex: "education", key: "education" },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    { title: "Experience", dataIndex: "experience", key: "experience" },
    { title: "Domain", dataIndex: "domain", key: "domain" },
    // {
    //   title: "Skills Percentage",
    //   dataIndex: "skills_perc",
    //   key: "skills_perc",
    //   render: (text) => `${text.toFixed(2)}%`,
    // },
    // {
    //   title: "Resume Match",
    //   dataIndex: "info_perc",
    //   key: "info_perc",
    //   render: (text) => (
    //     <div style={{ width: 100, height: 100 }}>
    //       <CircularProgressbar value={text} text={`${text.toFixed(2)}%`} />
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="dashboard">
      <h1>Stored Resumes</h1>
      <Input
        placeholder="Search"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, width: 300 }}
      />
      {isLoading ? (
        <Spin />
      ) : (
        <Table
          dataSource={filteredData}
          columns={columns}
          rowKey={(record) => record.filename}
        />
      )}
    </div>
  );
};

export default StoredResume;
