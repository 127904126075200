import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { NotificationManager } from "react-notifications";
import { Modal } from "../components/Modal";
import { BASE } from "../apis/constant";

const Dashboard = () => {
  const navigate = useNavigate();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleFileUpload = () => {
    if (selectedFiles.length > 0) {
      setIsModalOpen(true);
    } else {
      console.log("No files selected");
      NotificationManager.warning("Please select files first");
    }
  };

  const handleSubmitJD = async (jobDescription, requiredExperience) => {
    if (selectedFiles.length > 0) {
      let formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("document", file);
      });
      formData.append("job_description", jobDescription);
      formData.append("experience", requiredExperience);
      try {
        const response = await fetch(BASE.resumeParse, {
          headers: {
            Accept: "application/json",
          },
          method: "POST",
          body: formData,
        });
        const result = await response.json();
        if (response.ok) {
          console.log("Files uploaded successfully");
          NotificationManager.success("Files uploaded successfully");
          setIsModalOpen(false);
          console.log(result);
          navigate("/parsedResume", { state: { data: result } });
        } else {
          console.error("File upload failed");
          NotificationManager.error("File upload failed");
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        NotificationManager.error("Error uploading files");
      }
    } else {
      console.log("No files selected");
      NotificationManager.warning("Please upload files first");
      navigate("/parsedResume");
    }
  };

  return (
    <div className="dashboard">
      <h1>Brainvire Resume Parser</h1>
      <p>Please Select Resumes For Parsing</p>
      <div className="file-upload">
        <input
          type="file"
          className="input-name"
          accept=".pdf"
          multiple
          onChange={handleFileChange}
        />
        <button onClick={handleFileUpload}>Upload PDFs</button>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleSubmitJD}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
