import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Spin } from "antd";
import { CircularProgressbar } from "react-circular-progressbar";

const ParsedResumeInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { data } = location.state || { data: null };

  if (!data || !data.resumes) {
    return <div className="dashboard">No data available</div>;
  }

  const goToDashboard = () => {
    setIsLoading(true);
    setTimeout(() => {
      navigate("/uploadResume");
    }, 1000);
  };

  return (
    <div className="dashboard">
      <div className="parsed-resumes">
        <h1>Parsed Resumes</h1>
        {data.resumes.map((resume, index) => (
          <div key={index} className="parsed-resume">
            <h2>Resume {index + 1}</h2>
            <table>
              <tbody>
                <tr>
                  <th>Filename</th>
                  <td>{resume.filename}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{resume.data.NAME}</td>
                </tr>
                <tr>
                  <th>Contact</th>
                  <td>{resume.data.CONTACT}</td>
                </tr>
                <tr>
                  <th>LinkedIn</th>
                  <td>
                    <a
                      href={`https://${resume.data.LINKEDIN}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {resume.data.LINKEDIN}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Skills</th>
                  <td>{resume.data.SKILLS}</td>
                </tr>
                <tr>
                  <th>Experience</th>
                  <td>{resume.data.EXPERIENCE}</td>
                </tr>
                <tr>
                  <th>Education</th>
                  <td>{resume.data.EDUCATION}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{resume.data.ADDRESS}</td>
                </tr>
                <tr>
                  <th>Skills Percentage</th>
                  <td>{resume.data.skills_perc.toFixed(2)}%</td>
                </tr>
                <tr>
                  <th>Resume Match</th>
                  <td>
                    <div style={{ width: 100, height: 100 }}>
                      <CircularProgressbar
                        value={resume.data.info_perc}
                        text={`${resume.data.info_perc.toFixed(2)}%`}
                      />
                    </div>
                  </td>{" "}
                </tr>
              </tbody>
            </table>
          </div>
        ))}
        <Button type="primary" onClick={goToDashboard}>
          {isLoading ? <Spin /> : "Go To Dashboard"}
        </Button>
      </div>
    </div>
  );
};

export default ParsedResumeInfo;
