import React, { useState } from "react";

export const Modal = ({ isOpen, onClose, onSubmit }) => {
  const [jobDescription, setJobDescription] = useState("");
  const [requiredExperience, setRequiredExperience] = useState("");
  const [validationError, setValidationError] = useState(false);

  const handleSubmit = () => {
    if (!jobDescription || !requiredExperience) {
      setValidationError(true);
      return;
    }
    onSubmit(jobDescription, requiredExperience);
    setValidationError(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`overlay ${isOpen ? "open" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className="modal">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <h2 className="file-upload">File Upload in Progress</h2>
        <p>
          Your file is being uploaded. Please enter job description and the
          required experience...
        </p>
        <textarea
          placeholder="Job Description"
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          rows="10"
          cols="50"
          className={validationError && !jobDescription ? "error" : ""}
        />
        <input
          type="text"
          placeholder="Required Experience"
          value={requiredExperience}
          onChange={(e) => setRequiredExperience(e.target.value)}
          className={validationError && !requiredExperience ? "error" : ""}
        />
        <button onClick={handleSubmit}>Submit JD</button>
      </div>
    </div>
  );
};
