import React, { useState } from "react";
import "./LoginForm.css";
import { NotificationManager } from "react-notifications";

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const predefinedUsername = "admin";
    const predefinedPassword = "123456";

    setTimeout(() => {
      if (username === predefinedUsername && password === predefinedPassword) {
        onLogin();
      } else {
        NotificationManager.error("Invalid username or password");
      }
      setLoading(false);
    }, 2000);
  };

  return (
    <div className="login-card">
      <h2>Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={loading}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        <button type="submit" className="login-button" disabled={loading}>
          {loading ? <div className="loader"></div> : "Login"}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
