// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import ParsedResumeInfo from "./pages/ParsedResumeInfo";
import StoredResume from "./pages/StoredResume";
import Layout from "./components/Layout";

import "./assets/css/styles.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route element={<Layout />}>
          <Route path="/uploadResume" element={<Dashboard />} />
          <Route path="/parsedResume" element={<ParsedResumeInfo />} />
          <Route path="/storedResume" element={<StoredResume />} />
        </Route>
      </Routes>
      <NotificationContainer />
    </Router>
  );
};

export default App;
