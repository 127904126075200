import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DefaultIcon from "@mui/icons-material/DocumentScanner";
import "./Sidebar.css";

const navItems = [
  { name: "Resume Parsing", path: "/uploadResume", icon: <DefaultIcon /> },
  { name: "Stored Resumes", path: "/storedResume", icon: <DefaultIcon /> },
];

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <section className="page sidebar-3-page">
      <aside className={`sidebar-3 ${isOpen ? "open" : ""}`}>
        <div className="inner">
          <header>
            <button
              type="button"
              className="sidebar-3-burger"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span>{isOpen ? <CloseIcon /> : <MenuIcon />}</span>
            </button>
          </header>
          <nav>
            {navItems.map((item) => (
              <button
                className="menu-item"
                key={item.name}
                type="button"
                onClick={() => navigate(item.path)}
              >
                {item.icon}
                <p className="menu-item-name">{item.name}</p>
              </button>
            ))}
          </nav>
        </div>
      </aside>
    </section>
  );
};
